import {AfterViewInit, Component} from '@angular/core';
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {environment} from "../environments/environment";
import {SidebarService} from "./sidebar/sidebar.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BooksyDialogComponent} from "./booksy-dialog/booksy-dialog.component";
import {MetaPixelService} from "./services/meta-pixel.service";
import {getDatabase, onValue, ref} from "firebase/database";

interface ToastData {
  readonly active: boolean;
  readonly title: string;
  readonly details: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  readonly title = 'fizjo-strug';
  toastData: ToastData = {
    active: false,
    title: '',
    details: ''
  }

  ngAfterViewInit(): void {
    window.addEventListener("scroll", this.reveal);
    window.addEventListener("scroll", () => this.sidebarService.closeSidebar());
  }

  reveal() {
    document.querySelectorAll(".reveal")
      .forEach(e => {
        const elementTop = e.getBoundingClientRect().top;
        if (elementTop < window.innerHeight - 50) {
          e.classList.add("active");
        } else {
          e.classList.remove("active");
        }
      })
  }

  constructor(private readonly sidebarService: SidebarService,
              private readonly modalService: NgbModal,
              private readonly pixelService: MetaPixelService) {
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getDatabase(app);
    const homepageNotice = ref(db, 'homepageNotice');
    onValue(homepageNotice,
      snapshot => {
        this.toastData = snapshot.val();
      },
      error => {
        console.log(error);
      });
  }

  openBooksyDialog(): void {
    this.pixelService.trackScheduleInitiated();
    this.modalService.open(BooksyDialogComponent, {
      keyboard: false,
      beforeDismiss: () => false
    });
  }

}
