import {Component} from '@angular/core';
import {faQuoteRight, faStar} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-reviews-section',
  templateUrl: './reviews-section.component.html',
  styleUrls: ['./reviews-section.component.scss']
})
export class ReviewsSectionComponent {

  protected readonly faStar = faStar;
  protected readonly faQuoteRight = faQuoteRight;

  readonly reviews: Array<[string, string]> = [
    ['Krzysztof', `Profesjonalne i indywidualne podejście do pacjenta. Terapia prowadzona w miłej atmosferze i z
                   uśmiechem na twarzy. Widać, że Pan Przemek wykonuję swoją pracę z pasją i zaangażowaniem. Polecam w
                   100%`],
    ['Karolina', `Profesjonalne podejście do pacjenta. Omówiony problem i prawidłowo dobrana rehabilitacja w miłej
                  atmosferze:)`],
    ['Dominik', `Profesjonalizm, wiedza, świetny kontakt z pacjentem, super podejście to tylko kilka z wielu cech Pana
                 Przemysława. Długi czas męczyłem się z kilkoma dolegliwościami, z pomocą Pana Przemka udało mi się
                 wszystkie zlikwidować. Serdecznie polecam`],
    ['Tomasz', `Profesjonalizm w połączeniu z miłą atmosferą i świetnym kontaktem z pacjentem. Wiele lat borykałem się z
                bólem biodra i przeprowadzałem obszerne badania w szpitalach, po których lekarze rozkładali ręce i
                mówili, że "wszystko jest w porządku". Pan Strug przeprowadził ze mną obszerny wywiad, wykonał terapię,
                a także przekazał dokładne polecenia dotyczące rehabilitacji i ćwiczeń, dzięki czemu po raz pierwszy od
                lat mogę uprawiać sport regularnie.`],
    ['Patryk', `Uczęszczanie w gabinecie Pana Przemysława było jedną z lepszych decyzji w ostatnich miesiącach. Od
                dłuższego czasu borykałem się z bólem pleców, a każda próba wstania z pozycji leżącej kończyła się
                bólem. Na szczęście w końcu znalazłem miejsce gdzie udało się skutecznie mi pomoc! \nPolecam każdemu,
                kto ceni sobie profesjonalizm i realne działania! \nPozdrawiam!`]
  ];
}
