<div class="section section-dark text-center" id="pierwsza-wizyta">
  <div class="container">
    <h1 class="title">Pierwsza wizyta</h1>
    <hr class="section-hr reveal fade-width"/>
    <div class="row">
      <div class="col-lg-12">
        <p class="description text-justify">
          Pierwsza wizyta fizjoterapeutyczna składa się zazwyczaj z obszernego wywiadu, zapoznania się z dokumentacją
          medyczną oraz badania funkcjonalnego, ortopedycznego i/lub neurologicznego. Na potrzeby łatwego
          przeprowadzenia badania należy zabrać ze sobą luźny, niekrępujący ubiór, który pozwoli na dostęp do
          problematycznej okolicy. Należy również przynieść wszelką dokumentację medyczną (w tym płyty z badań
          obrazowych).
          <br/><br/>
          W przypadku braku dokumentacji badanie fizjoterapeutyczne i diagnostyka funkcjonalna pozwoli na ocenę oraz
          zakwalifikowanie/odrzucenie danego przypadku z procesu rehabilitacji. W przypadku stwierdzenia dolegliwości
          wykraczającej poza kompetencje fizjoterapeuty zasugerowana zostanie wizyta u odpowiedniego specjalisty.
        </p>
        <br/>
      </div>
    </div>
  </div>
</div>
