import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NavbarComponent} from './navbar/navbar.component';
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {FooterComponent} from './footer/footer.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {NgbAccordionModule, NgbActiveModal, NgbCarousel, NgbSlide, NgbToast} from "@ng-bootstrap/ng-bootstrap";
import {AboutMeSectionComponent} from './about-me-section/about-me-section.component';
import {FirstVisitSectionComponent} from './first-visit-section/first-visit-section.component';
import {ServicesSectionComponent} from './services-section/services-section.component';
import {GallerySectionComponent} from './gallery-section/gallery-section.component';
import {ContactSectionComponent} from './contact-section/contact-section.component';
import {ReviewsSectionComponent} from './reviews-section/reviews-section.component';
import {NgOptimizedImage} from "@angular/common";
import {BooksyDialogComponent} from "./booksy-dialog/booksy-dialog.component";

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    AboutMeSectionComponent,
    FirstVisitSectionComponent,
    ServicesSectionComponent,
    GallerySectionComponent,
    ContactSectionComponent,
    ReviewsSectionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    LeafletModule,
    NgbAccordionModule,
    NgbCarousel,
    NgbSlide,
    NgOptimizedImage,
    BooksyDialogComponent,
    NgbToast
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

}
