import {Component} from '@angular/core';

interface AccordionItem {
  title: string;
  description: string;
}

@Component({
  selector: 'app-about-me-section',
  templateUrl: './about-me-section.component.html',
  styleUrls: ['./about-me-section.component.scss']
})
export class AboutMeSectionComponent {

  protected readonly accordionItems: Array<AccordionItem> = [
    {
      title: '- dysfunkcjach kręgosłupa,',
      description: 'Dyskopatie, ostre stany bólowe, ograniczenia ruchu'
    },
    {
      title: '- dysfunkcjach mięśniowo-powięziowych,',
      description: 'Ograniczenia zakresu ruchu, bóle mięśni, podwyższone napięcia mięśniowe'
    },
    {
      title: '- dysfunkcjach nerwowo-mięśniowych,',
      description: 'Osłabienie siły mięśniowej, bóle promieniujące, stany ostre w obrębie korzeni nerwowych'
    },
    {
      title: '- stanach po artroskopii,',
      description: `Staw kolanowy — rekonstrukcja więzadeł, usunięcie lub szycie łąkotki
                    Staw skokowy — rekonstrukcja chrząstki stawowej, rekonstrukcja więzadeł w obrębie stawu skokowego`
    },
    {
      title: '- urazach sportowych.',
      description: 'Skręcenia, naciągnięcia'
    }
  ]

}
