import {Component} from '@angular/core';

@Component({
  selector: 'app-gallery-section',
  templateUrl: './gallery-section.component.html',
  styleUrls: ['./gallery-section.component.scss']
})
export class GallerySectionComponent {

  previousImageId(i: number, size: number): number {
    const modulo = ((-i % size) + size) % size;
    return size - modulo;
  }

}
