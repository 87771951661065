<div class="section" id="opinie">
  <div class="container text-center">
    <h1 class="title">Opinie</h1>
    <hr class="section-hr reveal fade-width"/>
    <ngb-carousel [showNavigationArrows]="false" [showNavigationIndicators]="false" [interval]="8000" role="group"
                  aria-roledescription="carousel">
      <ng-template ngbSlide *ngFor="let r of reviews">
        <div class="row w-75">
          <div class="d-flex flex-column">
            <h2>
              <i>
                <fa-icon [icon]="faQuoteRight"></fa-icon>
              </i>
            </h2>
            <p class="review text-center flex-grow-1">{{ r[1] }}</p>
            <span class="review-author">{{ r[0] }}</span>
            <h2 class="">
              <i *ngFor="let _ of [1,2,3,4,5]">
                <fa-icon [icon]="faStar"></fa-icon>
              </i>
            </h2>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
